declare global {
    interface Window {
        _env_: {
            REACT_APP_SITE_URL: string | undefined
            REACT_APP_WEBSOCKET_URL: string | undefined
            REACT_APP_SLOTS_PER_EPOCH: string
            REACT_APP_RPC_URL: string | undefined
            REACT_APP_TRANSACTION_GET_DELAY: string | undefined
        }
    }
}

export const SITE_URL = (typeof window !== 'undefined' && window._env_
  ? window._env_.REACT_APP_SITE_URL
  : process.env.REACT_APP_SITE_URL) || 'https://explorer.waterfall.network';

export const RPC_URL = (typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_RPC_URL : process.env.REACT_APP_RPC_URL)
    || 'https://rpc.waterfall.network';

export const WEBSOCKET_URL = (typeof window !== 'undefined' && window._env_
  ? window._env_.REACT_APP_WEBSOCKET_URL
  : process.env.REACT_APP_WEBSOCKET_URL) || 'wss://explorer.waterfall.network';

export const SLOTS_PER_EPOCH = (typeof window !== 'undefined' && window._env_
  ? parseInt(window._env_.REACT_APP_SLOTS_PER_EPOCH, 10)
  : parseInt(process.env.REACT_APP_SLOTS_PER_EPOCH as string, 10)) || 32;

export const TRANSACTION_GET_DELAY = (typeof window !== 'undefined' && window._env_
  ? Number(window._env_.REACT_APP_TRANSACTION_GET_DELAY)
  : Number(process.env.REACT_APP_TRANSACTION_GET_DELAY)) || 2000;
