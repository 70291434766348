import Web3 from 'web3';
import { ServerOperationsTypes, Transaction, TransactionInfo } from '../types/transaction';
import { tokenProperties } from '../hook/main';

const web3 = new Web3();
const getOperationType = (operation: string): ServerOperationsTypes => {
  let type = '';
  switch (operation) {
    case web3.wat.OP.CREATE: {
      type = 'Create';
      break;
    }
    case web3.wat.OP.TRANSFER: {
      type = 'Transfer';
      break;
    }
    case web3.wat.OP.TRANSFER_FROM: {
      type = 'Transfer';
      break;
    }
    case web3.wat.OP.MINT: {
      type = 'Mint';
      break;
    }
    case web3.wat.OP.BURN: {
      type = 'Burn';
      break;
    }
    default: {
      type = '';
      break;
    }
  }
  return <ServerOperationsTypes>type;
};

export const toLocalTransaction = async (
  item: Transaction,
): Promise<TransactionInfo | undefined> => {
  const operation = getOperationType(item?.decodeInput?.operation || '');
  if (!operation) return undefined;
  console.log(item);
  let tokenProp;
  try {
    if (!operation || !item.to || item.decodeInput.name) throw new Error();
    const prop = await tokenProperties(item.to);
    tokenProp = {
      data: prop,
    };
  } catch {
    tokenProp = undefined;
  }
  return {
    hash: item?.hash,
    standard: item?.decodeInput?.standard,
    from: item?.from,
    to: item?.decodeInput?.to,
    tokenAddress: item?.to,
    // eslint-disable-next-line no-nested-ternary
    name: tokenProp?.data?.name
      ? tokenProp?.data?.name
    // eslint-disable-next-line no-nested-ternary
      : item?.decodeInput?.name
        ? item?.decodeInput?.name
        : '—',
    // eslint-disable-next-line no-nested-ternary
    symbol: tokenProp?.data?.symbol
      ? tokenProp?.data?.symbol
    // eslint-disable-next-line no-nested-ternary
      : item?.decodeInput?.symbol
        ? item?.decodeInput?.symbol
        : '—',
    // eslint-disable-next-line no-nested-ternary
    value: item.decodeInput && item.decodeInput.standard !== 721
    // eslint-disable-next-line no-nested-ternary
      ? item?.decodeInput?.value || item?.decodeInput?.totalSupply
        ? item?.decodeInput?.value && item?.decodeInput?.value !== '0'
          ? item?.decodeInput?.value
          : String(item?.decodeInput?.totalSupply || '')
        : item.value && item.value !== '0'
          ? item.value
          : '0'
      : String(item.value),
    tokenId: operation === ServerOperationsTypes.Transfer ? item?.decodeInput?.value : item?.decodeInput?.tokenId,
    operation,
    timestamp: item?.timestamp,
  };
};
