import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import Web3 from 'web3';
import { queryParamsFields, queryParamsValues, queryRoutes as routes } from '../constants/routes';
import { useConection } from './conection';
import { blockData } from '../types/block';
import { useGetBlocks } from './block';
import { RPC_URL } from '../constants/env';
import { IProperty } from '../types/tokens';

export const web3 = new Web3(new Web3.providers.HttpProvider(RPC_URL));

export const tokenProperties = async (tokenAddress: string, block?: string, tokenID?: string): Promise<IProperty | undefined> => {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await web3.wat.tokenProperties(web3.utils.toChecksumAddress(tokenAddress), block || '', tokenID || '');
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

export const useGetQueryParams = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const filter = searchParams.get(queryParamsFields.filter);
  const hash = searchParams.get(queryParamsFields.hash) || undefined;
  const timestamp = searchParams.get(queryParamsFields.timestamp) || undefined;

  const useFilter = filter === queryParamsValues.after;

  if (!useFilter || !(filter || hash)) return {};

  return hash ? { cursor: hash } : { after: timestamp };
};

export const useGetDAG = () => {
  const [data, setData] = useState<blockData | []>([]);
  const params = useGetQueryParams();
  const { initialData, hasMore, fetchMore } = useGetBlocks({
    tree: true,
    params: 'cursor' in params ? { cursor: params.cursor } : params,
  });

  const { data: preData } = useConection({ path: routes.main });

  useEffect(() => {
    const actualData = params.cursor || params.after ? [...(initialData || [])] : [...(initialData || []), ...(preData || [])];
    if (!actualData || !actualData.length) return;

    let newData = [...data];

    actualData.forEach((block) => {
      const existingBlockWithNumber = data.find((b) => b.hash === block.hash && b.number);
      const existingBlockWithoutNumber = data.find((b) => b.hash === block.hash && !b.number);

      if (existingBlockWithNumber) {
        return;
      }
      if (existingBlockWithoutNumber) {
        const index = newData.findIndex((b) => b.hash === block.hash);
        if (index !== -1) {
          newData[index] = block;
        }
        return;
      }
      newData.push(block);
    });

    newData = newData.sort((a, b) => b.slot - a.slot);
    const first = newData[newData.length - 1];
    const last = newData[0];

    const firstEpochSlot = first.slot;
    const lastEpochSlot = last.slot;
    const lastSplitEpochSlot = lastEpochSlot - 128;
    if (firstEpochSlot <= lastSplitEpochSlot) newData = newData.filter((b, i) => i < 64 || b.slot >= lastSplitEpochSlot);
    setData(newData);
  }, [params.cursor || params.after ? null : preData, initialData]);

  return {
    blocks: data, hasMore, fetchMore,
  };
};
