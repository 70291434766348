import { useEffect, useState } from 'react';
import { queryRoutes as routes } from '../constants/routes';
import { Query, useQuery } from './query';
import { BlockType, blockData, fullBlockType } from '../types/block';
import { useGetBlockTransactions } from './transaction';

interface BlocksResponse {
    data: Array<BlockType>
    paging: {
        hasMore: boolean
        nextCursor?: string
    }
}

export const useGetBlocks = (options?: {
  tree?: boolean;
  dags?: blockData
  params?: {
    cursor?: string,
    after?: string,
  }
}) => {
  const { tree, params } = options || {};

  const [blocks, setBlocks] = useState<Array<BlockType>>();
  const [isFetch, setFetch] = useState(true);
  const {
    data: response, url, query, error,
  } = useQuery<BlocksResponse>({
    path: routes.blocks,
    params: { limit: 50 },
    queryParams: params,
  });
  const fetchMore = async () => {
    if (response?.paging?.nextCursor && isFetch) {
      setFetch(false);
      url.search = '';
      url.searchParams.set('limit', (50).toString());
      url.searchParams.set('cursor', response?.paging.nextCursor);
      await query();
      setFetch(true);
    }
  };

  useEffect(() => {
    if (!tree) {
      const actualData = response?.data;
      if (!actualData || !actualData.length) return;
      const newData = blocks || [];
      actualData.forEach((block: BlockType) => {
        if (blocks?.find((b) => b.hash === block.hash)) {
          return;
        }
        newData.push(block);
      });
      setBlocks(newData);
    }
  }, [response?.paging?.nextCursor]);

  useEffect(() => {
    if (options?.dags && !blocks?.[0]?.number) {
      setBlocks((prev) => prev?.map((item) => {
        if (item.number) return item;
        return { ...item, number: options.dags?.find((dag) => dag.hash === item.hash)?.number || null };
      }) || []);
    }
  }, [options?.dags?.[0]?.hash, blocks?.[0]?.hash]);

  return {
    data: blocks, initialData: response?.data, fetchMore, hasMore: (response?.paging?.hasMore && isFetch), error,
  };
};

export const useGetBlock = (hash: string) => {
  const [data, setData] = useState<fullBlockType>();
  const { data: response } = useQuery<BlockType>({ path: routes.block, params: { id: hash } });
  const {
    data: transactions, fetchMore, hasMore, error,
  } = useGetBlockTransactions(hash);

  useEffect(() => {
    if (!response) return;

    const { parentHashes, ...block } = response;

    Promise.all((parentHashes || [])?.map(async (el) => {
      const parentTx = await Query(`${routes.block}/${el}`);
      return parentTx;
    })).then((parentTxs) => {
      setData({
        ...block,
        parentHashes: parentTxs,
        transactions: transactions || [],
      });
    });
  }, [response, window.location, transactions]);

  return {
    data, fetchMore, hasMore, error,
  };
};
