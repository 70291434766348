import React from 'react';
import styled from 'styled-components';
import { ReactComponent as FilterIcon } from './assets/filterIcon.svg';

const InnerButton = styled.button`
  padding: 0.5rem;
  border: 0;
  border-radius: 4px;
  background-color: #55BAFF;
  cursor: pointer;
  line-height: 0;
`;

interface IProps {
    onClick: () => void
}

export const FilterButton: React.FC<IProps> = ({ onClick }) => (
  <InnerButton onClick={onClick}>
    <FilterIcon />
  </InnerButton>
);
