import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #213748;
  padding: 0 0 60px;
  
  @media (max-width: 560px) {
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1155px;
  box-sizing: border-box;
  padding: 0 10px;
  border-collapse: collapse;
  
  & > div > table {
    & td {
      width: auto;
    }   
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
width: 100%;
  margin-bottom: 24px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 5px;
  align-items: center;
`;

export const TokenWrapper = styled(DescriptionWrapper)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  gap: 5px;
  align-items: center;
  margin: 15px 0;
`;

export const EclipseText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;
