import React from 'react';
import { useParams } from 'react-router-dom';
import { Transaction } from '../../components/Transaction';
import { useGetTransaction, useGetTransactionInfo } from '../../hook/transaction';

export const TransactionContainer = () => {
  const { hash } = useParams<{ hash: string }>();
  const { data, error } = useGetTransaction(hash);
  const { info, loading } = useGetTransactionInfo([data]);
  return <Transaction data={data} error={error} info={info?.[0]} loading={loading} />;
};
