import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Box, Text } from '../common';
import { DatePicker } from '../DatePicker/DatePicker';
import { TFilterOption } from './types';

const InnerFilter = styled.div`
  position: absolute;
  top: 0;
  right: 0.5em;
  z-index: 1;
  padding: 1rem;
  background-color: rgba(38,85,126,1);
  border-radius: 4px;
`;

const InnerSwitch = styled.div`
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.87);
  border-radius: 50px;
  overflow: hidden;
  padding: 0;
  margin: 0;
  border: 0;
`;

const Option = styled.div<{$isActive: boolean}>`
  padding: 0.3em 1em;
  cursor: pointer;
  border-radius: 50px;
  ${({ $isActive }) => ($isActive && 'background-color:  rgb(16, 38, 54);')};
  
  &:first-child {
    margin-left: -1px;
  }
  &:last-child {
    margin-right: -1px;
  }
`;

const ApplyButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0.3em 1em;
  background-color: #102636;
  border-radius: 5px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #55BAFF;
  }
  &:active {
    background-color: rgba(16, 38, 54, 0.6);
  }
`;

const SimpleButton = styled.button`
  border: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;

const Input = styled.input`
  font-family: Roboto;
  outline: none;
  border-radius: 4px;
  border: 0;
  padding: 0.3em 1em;
  margin: 0;
`;

interface IProps {
  close: () => void
  submit: () => void
  clearFilters: () => void
  getSetFilter: (value: TFilterOption) => () => void
  isDateSelected: boolean
  date: Date | null
  hash: string
  changeHash: (event: ChangeEvent<HTMLInputElement>) => void
  changeDate: (date: Date) => void
}

export const FilterMenu: React.FC<IProps> = ({
  close, submit, getSetFilter, isDateSelected, date, hash, changeDate, changeHash, clearFilters,
}) => {
  const handleSubmit = () => {
    submit?.();
  };

  return (
    <>
      <InnerFilter>
        <Box flex column gap="0.5rem">
          <Box flex items="center" gap="1rem">
            <Text>Show after:</Text>
            <InnerSwitch>
              <Option $isActive={isDateSelected} onClick={getSetFilter(TFilterOption.date)}>
                <Text color={isDateSelected ? 'white' : 'black'}>date</Text>
              </Option>
              <Option $isActive={!isDateSelected} onClick={getSetFilter(TFilterOption.hash)}>
                <Text color={!isDateSelected ? 'white' : 'black'}>hash</Text>
              </Option>
            </InnerSwitch>
          </Box>
          {isDateSelected
            ? <DatePicker onChange={changeDate} selected={date} showTimeSelect customInput={<Input />} />
            : <Input onChange={changeHash} value={hash} />}
          <Box flex justify="end" gap="1rem">
            <SimpleButton onClick={close}>
              <Text decoration="underline">cancel</Text>
            </SimpleButton>
            <SimpleButton onClick={clearFilters}>
              <Text decoration="underline">clear</Text>
            </SimpleButton>
            <ApplyButton onClick={handleSubmit}>
              <Text>apply</Text>
            </ApplyButton>
          </Box>
        </Box>
      </InnerFilter>
    </>
  );
};
