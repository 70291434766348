import React, { ReactNode } from 'react';

import { Text } from '../Typography/Text';
import {
  Body, Column, Head, Row, TableContainer, TableLink, Title, Wrapper,
} from './styles';
import { ColumnType, TableElement, TableProps } from './types';

export const PrimaryTable = <T extends TableElement, >({ columns, items, layoutFixed }: TableProps<T>) => {
  const LinkedColumn: React.FC<{to?: string} & Partial<ColumnType<T>>> = ({ to, children, ...props }) => (to ? (
    <Column {...props}>
      <TableLink to={to} />
      {children}
    </Column>
  ) : <Column>{children}</Column>);

  const getItem = (item: T, el: ((data: T) => ReactNode) | keyof T) => (typeof el === 'function'
    ? el(item) : item[el]);

  return (
    <Wrapper>
      <TableContainer layoutFixed={layoutFixed}>
        <Head>
          <Row>
            {columns.map((el) => (
              <Title key={el.title} {...el}>
                <Text size={18}>{el.title}</Text>
              </Title>
            ))}
          </Row>
        </Head>
        <Body>
          {items.map((item, idx) => (
            <Row key={idx}>
              {columns.map((el) => (
                <>
                  {el.render && !getItem(item, el.dataKey) ? el.render(item) : (
                    <LinkedColumn to={item.linkTo} key={el.title} {...el}>
                      <Text>
                        {getItem(item, el.dataKey)}
                      </Text>
                    </LinkedColumn>
                  )}
                </>
              ))}
            </Row>
          ))}
        </Body>
      </TableContainer>
    </Wrapper>
  );
};
