import { useEffect, useRef, useState } from 'react';
import { w3cwebsocket as Websocket } from 'websocket';
import { WEBSOCKET_URL } from '../constants/env';
import { BlockType } from '../types/block';

interface queryParams {
    path?: string
    params?: string
}

export const useConection = ({ path, params }: queryParams) => {
  const URL = WEBSOCKET_URL + (path || '') + (params ? `/${params}` : '');
  const ws = useRef<null | Websocket>(null);
  const [data, setData] = useState<Array<BlockType>>();
  useEffect(() => {
    ws.current = new Websocket(URL);
    ws.current.onopen = () => console.log('connected!');
    if (!ws.current) return;

    ws.current.onerror = (err) => console.log('error', err);
    ws.current.onmessage = (res) => {
      const _data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data;
      // console.log(_data);
      setData(_data);
    };
    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ws.current.close();
    };
  }, []);

  return {
    data,
  };
};
