import React from 'react';
import InfinityScroll from 'react-infinite-scroller';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { AccountType } from '../../types/account';
import { Message, Center } from './styles';
import { ColumnType, TableElement } from '../Table/types';
import { SecondaryTable } from '../Table/SecondaryTable';
import { Br } from '../Typography/Br';
import { routes } from '../../constants/routes';
import { PrimaryTable } from '../Table';
import { Text } from '../Typography/Text';
import { txColumns } from '../Transaction/columns';
import { Layout } from '../Layout';
import { TokenWrapper } from '../Transaction/styles';
import { CopyIcon } from '../../helper/CopyIcon/CopyIcon';

interface Props {
    data?: AccountType;
    error: string;
    hasMore?: boolean
    fetchMore: (page: number) => void
}

const columns: ColumnType<AccountType>[] = [
  {
    title: 'Balance (Water)',
    dataKey: 'balance',
  },
  {
    title: 'User Tokens',
    dataKey: (item) => item.tokens?.map((token) => (
      <TokenWrapper key={token?.hash}>
        {token?.name}
        {' '}
        (
        {token?.symbol}
        )
        <CopyIcon dataToCopy={token?.hash} />
      </TokenWrapper>
    )),
  },
];

interface AccountItemType extends AccountType, TableElement {}

export const Account: React.FC<Props> = ({
  data: account, error, hasMore, fetchMore,
}) => {
  const currentAddress = window.location.pathname.split('/')?.[2];
  const _txColumns = [...txColumns];
  _txColumns[0].title = `Transactions(${account?.countTx || 0})`;
  return (
    <Layout>
      <Br indent={40} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {!account?.address ? (
        !account?.address && error.length > 0 ? (
          <Center>
            <Text>
              Sorry, We are unable to locate this address
              {currentAddress ? `: ${currentAddress}` : ''}
            </Text>
          </Center>
        ) : (
          <PacmanLoader loading color="#008eee" speedMultiplier={2} />
        )
      ) : (
        <>
          <SecondaryTable<AccountItemType>
            items={account || []}
            columns={columns}
            title="Account "
            copyText={account?.address}
          />
          <Br indent={40} />
          {account.transactions.length > 0 ? (
            <>
              {account.transactions ? (
                <InfinityScroll hasMore={hasMore} loadMore={fetchMore}>
                  <PrimaryTable
                    items={account?.transactions.map((el) => ({
                      ...el,
                      linkTo: `${routes.transaction}/${el.hash}`,
                    })) || []}
                    columns={_txColumns}
                    layoutFixed
                  />
                </InfinityScroll>
              ) : null}
            </>
          ) : (
            <Message>
              <Text size={20}>
                You have no transactions yet.
              </Text>
            </Message>
          )}
        </>
      )}
    </Layout>
  );
};
