import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Main } from '../../components/Main';
import { useGetDAG } from '../../hook/main';

const Container = () => {
  const { blocks, hasMore, fetchMore } = useGetDAG();

  return (<Main blocks={blocks} hasMore={hasMore} fetchMore={fetchMore} />);
};

export const MainContainer = () => {
  const [key, setKey] = useState('');
  const { search } = useLocation();

  useEffect(() => {
    setKey(search);
  }, [search]);

  return <Container key={key} />;
};
