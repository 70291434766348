import React, { ChangeEventHandler } from 'react';
import {
  Container, IconWrapper, Input, ErrorMessage,
} from './styles';
import { ReactComponent as SearchIcon } from './assets/searchIcon.svg';
import { Br } from '../Typography/Br';
import { Text } from '../Typography/Text';

export type variant = 'primary' | 'secondary'

interface Props {
  variant?: variant
  value: string,
  errorMsg?: string,
  onChange: ChangeEventHandler<HTMLInputElement>
  onSubmit: () => void
}

export const SearchComponent: React.FC<Props> = ({
  variant, onChange, value, onSubmit, errorMsg,
}) => {
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') onSubmit();
  };
  return (
    <>
      <Container>
        <Input onKeyDown={onKeyDown} variant={variant} placeholder="Search by Address / Txn Hash / Block" onChange={onChange} value={value} />
        <IconWrapper onClick={onSubmit}><SearchIcon /></IconWrapper>
        <Br indent={10} />
        {errorMsg && <ErrorMessage><Text variant="primary" color="white" size={16}>{errorMsg}</Text></ErrorMessage>}
      </Container>
    </>
  );
};
