import React from 'react';
import InfinityScroll from 'react-infinite-scroller';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { SLOTS_PER_EPOCH } from '../../constants/env';
import { Layout } from '../Layout';
import { ColumnType, TableElement } from '../Table/types';
import { BlockType, fullBlockType } from '../../types/block';
import { SecondaryTable } from '../Table/SecondaryTable';
import { Br } from '../Typography/Br';
import { routes } from '../../constants/routes';
import { formatDate } from '../../helper/formatDate';
import { PrimaryTable } from '../Table';
import { TransactionType } from '../../types/transaction';
import { Text } from '../Typography/Text';
import { txColumns } from '../Transaction/columns';

interface Props {
    data?: fullBlockType
    hasMore?: boolean
    fetchMore: (page: number) => void
    error: string
}

interface BlockItemType extends fullBlockType, TableElement {}

interface ParentItemType extends BlockType, TableElement {}

interface TransactionItemType extends TransactionType, TableElement {}

const blockColumnsShort: ColumnType<ParentItemType>[] = [
  {
    title: 'Parent hash',
    dataKey: 'hash',
  },
  {
    title: 'Number',
    dataKey: 'number',
    align: 'center',
  },
  {
    title: 'Height',
    dataKey: 'height',
    align: 'center',
  },
  {
    title: 'Parents',
    dataKey: (item) => item.parentHashes.length,
    align: 'center',
  },
  {
    title: 'Transactions',
    dataKey: (item) => item.countTx,
    align: 'center',
  },
  {
    title: 'Timestamp',
    dataKey: (item) => item.timestamp && formatDate(item.timestamp),
    align: 'center',
  },
];

export interface BlocksItemType extends BlockType, TableElement {}

export const blocksColumns: ColumnType<BlockType>[] = [
  {
    title: 'Hash',
    dataKey: 'hash',
  },
  {
    title: 'Author',
    dataKey: 'miner',
  },
  {
    title: 'Parents',
    dataKey: (item) => item?.parentHashes.length,
    align: 'center',
  },
  {
    title: 'Transactions',
    dataKey: (item) => item?.transactions.length,
    align: 'center',
  },
  {
    title: 'Height',
    dataKey: 'height',
    align: 'center',
  },
  {
    title: 'Timestamp',
    dataKey: (item) => item?.timestamp && formatDate(item.timestamp),
    align: 'center',
  },
];

const blockColumns: ColumnType<fullBlockType>[] = [
  {
    title: 'Number',
    dataKey: 'number',
    align: 'center',
  },
  {
    title: 'Height',
    dataKey: 'height',
    align: 'center',
  },
  {
    title: 'Era',
    dataKey: (item) => item.era && Number(item.era),
    align: 'center',
  },
  {
    title: 'Epoch(Slot)',
    dataKey: (item) => `${Math.floor(item.slot / SLOTS_PER_EPOCH)}(${(item.slot % SLOTS_PER_EPOCH) + 1})`,
    align: 'center',
  },
  {
    title: 'Timestamp',
    dataKey: (item) => item.timestamp && formatDate(item.timestamp),
  },
  // {
  //   title: 'Extra data',
  //   dataKey: 'extraData',
  // },
  {
    title: 'Base Fee Per Gas',
    dataKey: 'baseFeePerGas',
  },
  // {
  //   title: 'Difficulty',
  //   dataKey: 'difficulty',
  // },
  {
    title: 'Gas limit',
    dataKey: 'gasLimit',
  },
  {
    title: 'Gas used',
    dataKey: 'gasUsed',
  },
  // {
  //   title: 'Logs bloom',
  //   dataKey: 'logsBloom',
  // },
  {
    title: 'Author',
    dataKey: 'miner',
    getLink: (str) => `${routes.account}/${str}`,
  },
  // {
  //   title: 'Mix hash',
  //   dataKey: 'mixHash',
  // },
  // {
  //   title: 'Nonce',
  //   dataKey: 'nonce',
  // },
  {
    title: 'Receipts root',
    dataKey: 'receiptsRoot',
  },
  // {
  //   title: 'Sha 3 Uncles',
  //   dataKey: 'sha3Uncles',
  // },
  {
    title: 'Size (bytes)',
    dataKey: 'size',
  },
  {
    title: 'State root',
    dataKey: 'stateRoot',
  },
  // {
  //   title: 'Total difficulty',
  //   dataKey: 'totalDifficulty',
  // },
  {
    title: 'Transactions root',
    dataKey: 'transactionsRoot',
  },
  {
    title: 'Transactions count',
    dataKey: (item) => item.countTx,
  },
  {
    title: 'Checkpoint Base Fee Per Gas',
    dataKey: (item) => item.cpBaseFeePerGas && Number(item.cpBaseFeePerGas),
  },
  {
    title: 'Checkpoint Gas used',
    dataKey: (item) => item.cpGasUsed && Number(item.cpGasUsed),
  },
  {
    title: 'Checkpoint Hash',
    dataKey: 'cpHash',
    getLink: (str) => `${routes.block}/${str}`,
  },
  {
    title: 'Checkpoint Number',
    customLink: (item) => `${routes.block}/${item.cpHash}`,
    dataKey: (item) => item.cpNumber && Number(item.cpNumber),
  },
  {
    title: 'Checkpoint Receipts root',
    dataKey: (item) => item.cpReceiptsRoot && item.cpReceiptsRoot,
  },
  {
    title: 'Checkpoint State root',
    dataKey: (item) => item.cpStateRoot && item.cpStateRoot,
  },
];
export const Block: React.FC<Props> = ({
  data, fetchMore, hasMore, error,
}) => {
  const blockHash = window.location.pathname.split('/')?.[2];
  const _txColumns = [...txColumns];
  _txColumns[0].title = `Transactions(${data?.countTx || 0})`;
  return (
    <>
      <Layout title={data?.hash && 'Waterfall Block Search'}>
        {data?.message ? (
          <Text align="center">
            Sorry, We are unable to locate this block
            {blockHash ? `: ${blockHash}` : ''}
          </Text>
        ) : (
          <>
            {!data && <PacmanLoader loading color="#008eee" speedMultiplier={2} />}
            {!!data && <SecondaryTable<BlockItemType> items={data} columns={blockColumns} title="Block " copyText={data.hash} />}
            <Br indent={30} />
            {!!data?.parentHashes.length && <PrimaryTable<ParentItemType> columns={blockColumnsShort} items={data.parentHashes.map((el) => ({ ...el, linkTo: `${routes.block}/${el.hash}` }))} />}
            <Br indent={30} />
            {(!!data?.transactions.length) && !(error.length > 0) && <InfinityScroll hasMore={hasMore} loadMore={fetchMore}><PrimaryTable<TransactionItemType> layoutFixed columns={_txColumns} items={data.transactions.map((el) => ({ ...el, linkTo: `${routes.transaction}/${el.hash}` }))} /></InfinityScroll>}
          </>
        )}
      </Layout>
    </>
  );
};
