import React from 'react';
import InfinityScroll from 'react-infinite-scroller';
import PacmanLoader from 'react-spinners/PacmanLoader';
import PulseLoader from 'react-spinners/PulseLoader';

import { SLOTS_PER_EPOCH } from '../../constants/env';
import { Layout } from '../Layout';
import { PrimaryTable } from '../Table';
import { ColumnType, TableElement } from '../Table/types';
import { BlockType } from '../../types/block';
import { routes } from '../../constants/routes';
import { Br } from '../Typography/Br';
import { formatDate } from '../../helper/formatDate';
import { StyledCenterLoader } from '../common/styles';

interface Props {
    data?: BlockType[]
    hasMore?: boolean
    fetchData?: (page: number) => void
}

interface BlockItemType extends BlockType, TableElement {}

const blocksColumns: ColumnType<BlockType>[] = [
  {
    title: 'Hash',
    dataKey: 'hash',
  },
  {
    title: 'Author',
    dataKey: 'miner',
  },
  {
    title: 'Parents',
    dataKey: (item) => item.parentHashes.length,
    align: 'center',
  },
  {
    title: 'Txs',
    dataKey: (item) => item.transactions.length,
    align: 'center',
  },
  {
    title: 'Height',
    dataKey: 'height',
    align: 'center',
  },
  {
    title: 'Number',
    dataKey: 'number',
    align: 'center',
    render: () => (
      <StyledCenterLoader>
        <PulseLoader size={14} color="#E6E7E7" />
      </StyledCenterLoader>
    ),
  },
  {
    title: 'Epoch(Slot)',
    dataKey: (item) => `${Math.floor(item.slot / SLOTS_PER_EPOCH)}(${(item.slot % SLOTS_PER_EPOCH) + 1})`,
    align: 'center',
  },
  {
    title: 'Age',
    // dataKey: (item) => {
    //   if (item.timestamp) {
    //     return `${formatDistanceStrict(new Date(), item.timestamp * 1000)} ago`;
    //   }
    //   return '';
    // },
    dataKey: (item) => item.timestamp && formatDate(item.timestamp),
    align: 'center',
  },
];

export const Blocks: React.FC<Props> = ({ data, fetchData, hasMore }) => (
  <>
    {data && fetchData ? (
      <Layout title="Waterfall Block Search">
        <Br indent={20} />
        <InfinityScroll hasMore={hasMore} loadMore={fetchData}>
          <PrimaryTable<BlockItemType>
            items={data?.map((el) => ({ ...el, linkTo: `${routes.block}/${el.hash}` })) || []}
            columns={blocksColumns}
            layoutFixed
          />
        </InfinityScroll>
      </Layout>
    ) : (
      <Layout>
        <PacmanLoader loading color="#008eee" speedMultiplier={2} />
      </Layout>
    )}
  </>
);
