import React from 'react';
import {
  Container, Content, ContentMain, Title,
} from './styles';
import { Header } from '../Header';

interface Props {
    title?: string
}

export const Layout: React.FC<Props> = ({ children, title }) => (
  <Container>
    <Header />
    <Content>
      {title && <Title>{title}</Title>}
      {children}
    </Content>
  </Container>
);

export const MainLayout: React.FC<Props> = ({ children, title }) => (
  <Container>
    <Header />
    <ContentMain style={{ width: '100%' }}>
      {title && <Title>{title}</Title>}
      {children}
    </ContentMain>
  </Container>
);
