import React from 'react';
import { Blocks } from '../../components/Blocks';
import { useGetBlocks } from '../../hook/block';
import { useGetDAG } from '../../hook/main';

export const BlocksContainer = () => {
  const { blocks } = useGetDAG();
  const { data, fetchMore, hasMore } = useGetBlocks({ dags: blocks });

  return <Blocks data={data} fetchData={fetchMore} hasMore={hasMore} />;
};
