import React from 'react';
import { Br } from '../Typography/Br';
import { Layout } from '../Layout';
import { StatisticItem, StatisticTopType, StatisticType } from '../../types/statistic';
import { ColumnType, TableElement } from '../Table/types';
import { Text } from '../common';
import { SecondaryTable } from '../Table/SecondaryTable';
import { PrimaryTable } from '../Table';
import { DateWrapper, Title } from './styles';
import { formatDateString } from '../../helper/formatDate';
import { routes } from '../../constants/routes';

interface Props {
  statistic: StatisticType;
  top: StatisticTopType;
}

interface StatisticItemType extends StatisticType, TableElement {}

const columnsStatistic: ColumnType<StatisticType>[] = [
  {
    title: 'Staking/Rewards Amount',
    dataKey: (item) => (
      <Text>
        {Number(item.validatorTotalBalance - item.validatorTotalIgnoreBalance)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {' '}
        WATER
      </Text>
    ),
  },
  {
    title: 'Circulating Supply Amount',
    dataKey: (item) => (
      <Text>
        {Number(item.userTotalBalance - item.userTotalIgnoreBalance)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {' '}
        WATER
      </Text>
    ),
  },
  {
    title: 'Total Lock Amount',
    dataKey: (item) => (
      <Text>
        {Number(item.userTotalIgnoreBalance)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {' '}
        WATER
      </Text>
    ),
  },
  {
    title: 'Total Supply Amount',
    dataKey: (item) => (
      <Text>
        {Number(item.userTotalBalance + item.validatorTotalBalance)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {' '}
        WATER
      </Text>
    ),
  },
  {
    title: 'Last Update On',
    dataKey: (item) => (
      <DateWrapper>
        <Text>
          Slot:
          {' '}
          {item.slot}
        </Text>
        <Text>
          Date:
          {' '}
          {formatDateString(item?.timestamp) }
        </Text>
      </DateWrapper>

    ),
  },
];

export const topColumns: ColumnType<StatisticItem>[] = [
  {
    title: 'User Address',
    dataKey: '_id',
  },
  {
    title: 'Balance',
    dataKey: (item) => (
      <Text>
        {Number(item?.balance)?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
        {' '}
        WATER
      </Text>
    ),
  },
];

export const Statistic: React.FC<Props> = ({
  statistic,
  top,
}) => (
  <Layout title="Waterfall Balance Statistic">
    <SecondaryTable<StatisticItemType>
      items={statistic}
      columns={columnsStatistic}
      title="Statistic "
    />
    <Br indent={40} />
    <Title>Top 100 Holders</Title>
    <PrimaryTable
      items={top?.top?.map((el) => ({ ...el, linkTo: `${routes.account}/${el._id}` })) as TableElement[]}
      columns={topColumns as ColumnType<TableElement>[]}
    />
  </Layout>
);
