import React, { ReactNode } from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { ServerOperationsTypes, TransactionInfo, TransactionType } from '../../types/transaction';
import { DescriptionWrapper, EclipseText, Title } from './styles';
import { SecondaryTable } from '../Table/SecondaryTable';
import { SecondaryTableElement, TableElement } from '../Table/types';
import { Br } from '../Typography/Br';
import { routes } from '../../constants/routes';
import { Text } from '../Typography/Text';
import { PrimaryTable } from '../Table';
import { Layout } from '../Layout';
import { CopyIcon } from '../../helper/CopyIcon/CopyIcon';
import { blocksColumns, BlocksItemType } from '../Block';
import { TransactionCol } from './columns';

interface Props {
    data?: TransactionType;
    error: string;
    info?: TransactionInfo;
    loading: boolean;
}

interface TransactionColType extends TransactionType {description: string}
interface TransactionItemType extends TransactionColType, SecondaryTableElement, TableElement {}

const getDescription = (data: TransactionInfo): {title: string, descr: ReactNode} => {
  if (data?.operation === ServerOperationsTypes.Create) {
    return {
      title: `WRC-${data?.standard} Token ${data?.operation}`,
      descr: (
        <DescriptionWrapper>
          Create Token
          {' '}
          {data?.name}
          {' '}
          by
          <EclipseText>
            {data?.from}
          </EclipseText>
          <CopyIcon dataToCopy={data?.from} />
        </DescriptionWrapper>
      ),
    };
  }
  if (data?.operation === ServerOperationsTypes.Mint) {
    return {
      title: `WRC-${data?.standard} Token ${data?.operation}`,
      descr: (
        <DescriptionWrapper>
          Mint Token with ID:
          {' '}
          {data?.tokenId}
          {' '}
          in collection:
          {' '}
          {data?.name}
          {' '}
          <CopyIcon dataToCopy={data?.tokenAddress} />
          {' '}
          to
          <EclipseText>
            {data?.to}
          </EclipseText>
          <CopyIcon dataToCopy={data?.to} />
        </DescriptionWrapper>
      ),
    };
  }
  if (data?.operation === ServerOperationsTypes.Burn) {
    return {
      title: `WRC-${data?.standard} Token ${data?.operation}`,
      descr: (
        <DescriptionWrapper>
          Burn Token with ID:
          {' '}
          {data?.tokenId}
          {' '}
          from
          {' '}
          {data?.name}
          {' '}
          <CopyIcon dataToCopy={data?.tokenAddress} />
          {' '}
          by
          <EclipseText>
            {data?.from}
          </EclipseText>
          <CopyIcon dataToCopy={data?.from} />
        </DescriptionWrapper>
      ),
    };
  }
  if (data?.operation === ServerOperationsTypes.Transfer) {
    return {
      title: `WRC-${data?.standard} Token ${data?.operation}`,
      descr: data?.standard === 20
        ? (
          <DescriptionWrapper>
            Transfer From
            <EclipseText>
              {data?.from}
            </EclipseText>
            <CopyIcon dataToCopy={data?.from} />
            {' '}
            to
            <EclipseText>
              {data?.to}
            </EclipseText>
            <CopyIcon dataToCopy={data?.to} />
            {' '}
            for
            {' '}
            {data?.value}
            {' '}
            (
            {data?.symbol}
            {' '}
            <CopyIcon dataToCopy={data?.tokenAddress} />
            )
          </DescriptionWrapper>
        )
        : (
          <DescriptionWrapper>
            Transfer Token with ID:
            {' '}
            {data?.tokenId}
            {' '}
            collection:
            {' '}
            {data?.name}
            {' '}
            <CopyIcon dataToCopy={data?.tokenAddress} />
            {' '}
            from
            <EclipseText>
              {data?.from}
            </EclipseText>
            <CopyIcon dataToCopy={data?.from} />
            {' '}
            to
            <EclipseText>
              {data?.to}
            </EclipseText>
            <CopyIcon dataToCopy={data?.to} />
          </DescriptionWrapper>
        ),
    };
  }
  return { title: '', descr: '' };
};

export const Transaction: React.FC<Props> = ({
  data: tx, error, info, loading,
}) => {
  if (info) {
    const description = info && getDescription(info);
    TransactionCol.splice(3, 0, {
      title: description?.title || '',
      dataKey: () => description?.descr,
    });
  }
  const currentTxHash = window.location.pathname.split('/')?.[2];
  return (
    <Layout>
      { error.length > 0 || !tx?.hash || loading ? (
        <Title>
          <Text>
            {
              error.length > 0 ? (
                <>
                  Sorry, We are unable to locate this TxnHash
                  {currentTxHash ? `: ${currentTxHash}` : ''}
                </>
              ) : <PacmanLoader loading color="#008eee" />
            }
          </Text>
        </Title>
      ) : (
        <>
          <SecondaryTable<TransactionItemType>
            items={{ ...tx, description: '' } || []}
            columns={TransactionCol}
            title="Transaction "
            copyText={tx?.hash}
          />
          <Br indent={40} />
          {tx?.blocks.length ? (
            <PrimaryTable<BlocksItemType>
              items={tx ? tx.blocks.map((el) => ({
                ...el,
                linkTo: `${routes.block}/${el.hash}`,
              })) : []}
              columns={blocksColumns}
              layoutFixed
            />
          ) : null}
        </>
      )}
    </Layout>
  );
};
