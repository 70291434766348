import styled from 'styled-components';

export const CopyIconWrapper = styled.div`
  cursor: pointer;
  height: 18px;
  width: 18px;
  &:active {
    transform: translateY(4px);
  }
  svg {
    fill: white;
  }
`;

export const ContentWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;
