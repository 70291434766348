import React, {
  ChangeEvent, useEffect, useLayoutEffect, useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Box } from '../common';
import { FilterButton } from './FilterButton';
import { FilterMenu } from './FilterMenu';
import { TFilterOption } from './types';
import { queryParamsFields, queryParamsValues, routes } from '../../constants/routes';

export const Filter: React.FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  const [visible, setVisible] = useState<boolean>(false);
  const [filterOption, setFilterOption] = useState<TFilterOption>(TFilterOption.date);
  const [date, setDate] = useState<Date | null>(null);
  const [hash, setHash] = useState<string>('');

  const isDateSelected = filterOption === TFilterOption.date;

  const getSetFilter = (value: TFilterOption) => () => setFilterOption(value);

  const handleChangeDate = (date: Date) => {
    setDate(date);
  };
  const handleChangeHash = (event: ChangeEvent<HTMLInputElement>) => {
    setHash(event.target.value);
  };

  const handleOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };

  const handleClearFilters = () => {
    setDate(null);
    setHash('');
  };

  const handleSubmit = () => {
    const searchParams = new URLSearchParams();

    if (date || hash) {
      searchParams.set(queryParamsFields.filter, queryParamsValues.after);
    }
    if (isDateSelected && date) {
      const timestamp = Math.trunc(date.getTime() / 1000);
      searchParams.set(queryParamsFields.timestamp, timestamp.toString());
    }
    if (!isDateSelected && hash) {
      searchParams.set(queryParamsFields.hash, hash);
    }

    const queryString = searchParams.toString();

    push(`${routes.main}${queryString ? `?${queryString}` : ''}`);
    handleClose();
  };

  useEffect(() => {
    if (!search) return;
    const searchParams = new URLSearchParams(search);

    const filter = searchParams.get(queryParamsFields.filter);
    if (filter !== queryParamsValues.after) return;

    const timestamp = searchParams.get(queryParamsFields.timestamp);
    const hash = searchParams.get(queryParamsFields.hash);

    if (timestamp) setDate(new Date(+timestamp * 1000));
    if (hash) setHash(hash);
  }, [search]);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(search);

    const hash = searchParams.get(queryParamsFields.hash);

    setFilterOption(hash ? TFilterOption.hash : TFilterOption.date);
  }, []);

  return (
    <>
      <Box
        flex
        justify="end"
        width="100%"
        padding="0 0.5rem 0 0"
        relative
        minHeight={38}
      >
        {visible ? (
          <FilterMenu
            close={handleClose}
            submit={handleSubmit}
            getSetFilter={getSetFilter}
            isDateSelected={isDateSelected}
            changeDate={handleChangeDate}
            changeHash={handleChangeHash}
            clearFilters={handleClearFilters}
            date={date}
            hash={hash}
          />
        ) : <FilterButton onClick={handleOpen} />}
      </Box>
    </>
  );
};
