import React, { useRef } from 'react';
import { Text } from '../../Typography/Text';
import {
  Column, Row, TableLink, Wrapper,
} from '../styles';
import { ColumnType, SecondaryTableProps, TableElement } from '../types';
import {
  TableContainer, Body, Caption, CopyButton, CopyText,
} from './styles';

export const SecondaryTable = <T extends TableElement, >({
  columns, items, title, copyText,
}: SecondaryTableProps<T>) => {
  const copyElem = useRef<HTMLSpanElement>(null);
  const handleCopy = () => {
    if (copyElem && navigator?.clipboard?.writeText) navigator.clipboard.writeText((copyElem.current as HTMLSpanElement).innerText);
  };

  const LinkedColumn: React.FC<{to?: string} & Partial<ColumnType<T>>> = ({ to, children, ...props }) => (to ? (
    <Column {...props}>
      <TableLink to={to} />
      {children}
    </Column>
  ) : <Column>{children}</Column>);

  return (
    <Wrapper>
      <TableContainer>
        {(title || copyText) && (
        <Caption>
          <Text size={18}>
            {title}
            <CopyText ref={copyElem}>{copyText}</CopyText>
          </Text>
          {!!copyText && <CopyButton onClick={handleCopy}>copy</CopyButton>}
        </Caption>
        )}
        <Body>
          {
            Array.isArray(items) ? items.map((el, index) => (
              <Row key={index}>
                <LinkedColumn to={el.linkTo}><Text>{el.tableValue}</Text></LinkedColumn>
              </Row>
            )) : columns?.map((el, index) => (
              <Row key={index}>
                <LinkedColumn><Text>{el.title}</Text></LinkedColumn>
                <LinkedColumn
                  to={el?.customLink && typeof el?.customLink === 'function' ? el?.customLink(items) : el.getLink && el.getLink((typeof el.dataKey === 'function' ? el.dataKey(items) : items[el.dataKey])as string || '')}
                >
                  {el?.render ? el?.render(items) : (
                    <Text>{typeof el.dataKey === 'function' ? el.dataKey(items) : items[el.dataKey]}</Text>
                  )}
                </LinkedColumn>
              </Row>
            ))
          }
        </Body>
      </TableContainer>
    </Wrapper>
  );
};
