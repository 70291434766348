import styled from 'styled-components';
import { variant } from './index';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  
  @media (max-width: 650px) {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
  }
`;

export const Input = styled.input<{variant?: variant}>`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin: 0;
  border: 0;
  display: flex;
  align-items: center;
  padding: 0 45px 0 34px;;
  border-radius: 50px;
  background-color: ${({ variant }) => (variant === 'secondary' ? '#213748' : '#192F40')};
  outline: none;
  color: #fff;
  font-family: Roboto;

  &::placeholder {
    size: 18px;
    color: #fff;
  }
`;

export const IconWrapper = styled.button`
  position: absolute;
  right: 20px;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  
  @media (max-width: 650px) {
    top: 60px;
  }
`;
