import React from 'react';
import InfinityScroll from 'react-infinite-scroller';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Message, Center } from './styles';
import { ColumnType, TableElement } from '../Table/types';
import { SecondaryTable } from '../Table/SecondaryTable';
import { Br } from '../Typography/Br';
import { routes } from '../../constants/routes';
import { PrimaryTable } from '../Table';
import { Text } from '../Typography/Text';
import { Layout } from '../Layout';
import { TokensType } from '../../types/tokens';
import { Transaction, TransactionInfo } from '../../types/transaction';
import { formatDate } from '../../helper/formatDate';
import { CopyIcon } from '../../helper/CopyIcon/CopyIcon';
import { DescriptionWrapper, EclipseText } from '../Transaction/styles';

type TokenPageType = {
    token: TokensType | undefined;
    transactions: Transaction[];
    countTx: number;
};

interface Props {
    data?: TokenPageType;
    error: string;
    hasMore?: boolean;
    transactionInfo: TransactionInfo[];
    fetchMore: (page: number) => void;
}

interface TokenItemType extends TokensType, TableElement {}
interface TransactionItemType extends TransactionInfo, TableElement {}

export const Token: React.FC<Props> = ({
  data, error, hasMore, fetchMore, transactionInfo,
}) => {
  const currentAddress = window.location.pathname.split('/')?.[2];
  const txColumns: ColumnType<TransactionInfo>[] = [
    {
      title: `Transactions (${data?.countTx || 0})`,
      dataKey: (item) => <EclipseText>{item.hash}</EclipseText>,
    },
    {
      title: 'Operation',
      dataKey: 'operation',
    },
    {
      title: 'From',
      dataKey: (item) => <EclipseText>{item.from}</EclipseText>,
    },
    {
      title: 'To',
      dataKey: (item) => <EclipseText>{item.to}</EclipseText>,
    },
    {
      title: data?.token?.decimals ? 'Value' : 'Token ID',
      dataKey: (item) => <Text>{item?.standard === 721 ? item?.tokenId || '—' : item?.value || '—'}</Text>,
      align: 'center',
    },
    {
      title: 'Timestamp',
      dataKey: (item) => item.timestamp && formatDate(item.timestamp),
      align: 'center',
    },
  ];

  const columns: ColumnType<TokensType>[] = [
    {
      title: 'Name',
      dataKey: (item) => item?.name,
    },
    {
      title: 'Symbol',
      dataKey: (item) => item?.symbol,
    },
    {
      title: 'Standard',
      dataKey: (item) => <Text>{item?.decimals ? 'WRC-20' : 'WRC-721'}</Text>,
    },
    {
      title: 'Token Address',
      dataKey: (item) => (
        <DescriptionWrapper>
          <Text>
            {item?.hash}
          </Text>
          <CopyIcon dataToCopy={item?.hash} />
        </DescriptionWrapper>
      ),
    },
  ];
  if (data?.token?.decimals) {
    columns.push({
      title: 'Decimals',
      dataKey: (item) => item?.decimals,
    });
    columns.push({
      title: 'Total Supply',
      dataKey: (item) => item?.totalSupply,
    });
  }
  if (data?.token?.baseURI) {
    columns.push({
      title: 'Base URL',
      dataKey: (item) => item?.baseURI,
    });
  }
  return (
    <Layout>
      <Br indent={40} />
      {/* eslint-disable-next-line no-nested-ternary */}
      {!data?.token ? (
        !data?.token && error.length > 0 ? (
          <Center>
            <Text>
              Sorry, We are unable to locate this token
              {currentAddress ? `: ${currentAddress}` : ''}
            </Text>
          </Center>
        ) : (
          <PacmanLoader loading color="#008eee" speedMultiplier={2} />
        )
      ) : (
        <>
          <SecondaryTable<TokenItemType>
            items={data?.token || []}
            columns={columns}
            title="Token "
            copyText={data?.token?.hash}
          />
          <Br indent={40} />
          {data.transactions.length > 0 ? (
            <>
              {data.transactions ? (
                <InfinityScroll hasMore={hasMore} loadMore={fetchMore}>
                  <PrimaryTable<TransactionItemType>
                    items={transactionInfo.map((el) => ({
                      ...el,
                      linkTo: `${routes.transaction}/${el?.hash}`,
                    })) || []}
                    columns={txColumns}
                    layoutFixed
                  />
                </InfinityScroll>
              ) : null}
            </>
          ) : (
            <Message>
              <Text size={20}>
                This token have no transactions yet.
              </Text>
            </Message>
          )}
        </>
      )}
    </Layout>
  );
};
