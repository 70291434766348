import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Layout } from '../../components/Layout';
import { useGetStatistic } from '../../hook/statistic';
import { Statistic } from '../../components/Statistic';

export const StatissticContainer = () => {
  const { statistic, top } = useGetStatistic();

  if (!statistic || !top) {
    return (
      <>
        <Layout>
          <PacmanLoader loading color="#008eee" speedMultiplier={2} />
        </Layout>
      </>
    );
  }

  return <Statistic statistic={statistic} top={top} />;
};
