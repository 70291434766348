import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

import {
  StyledErrorIcon, StyledLabel, StyledSuccessIcon, StyledText,
} from './styles';
import { StatusType } from '../../types/transaction';

export const Label: React.FC<{status: StatusType}> = ({ status }) => (
  <StyledLabel $status={status}>
    {String(status) === StatusType.pending && <PulseLoader size={14} color="#E6E7E7" />}
    {String(status) === StatusType.success && <StyledSuccessIcon />}
    {String(status) === StatusType.error && <StyledErrorIcon />}
    <StyledText>{status}</StyledText>
  </StyledLabel>
);
