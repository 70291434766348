import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: #213748;
  padding: 0 0 60px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1155px;
  box-sizing: border-box;
  padding: 0 10px;
  border-collapse: collapse;
`;

export const Title = styled.div`
width: 100%;
  text-align: left;
  margin-bottom: 24px;
  padding-left: 16px;
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
width: 100%;
  margin-bottom: 24px;
  padding-left: 16px;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
`;
