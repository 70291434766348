import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;

    & > *:first-child {
        margin-right: 10px;
    }
`;
