import { useEffect, useState } from 'react';
import { queryRoutes as routes } from '../constants/routes';
import { useQuery } from './query';
import {
  Transaction, TransactionInfo, TransactionType,
} from '../types/transaction';
import { toLocalTransaction } from '../helper/transactions';
import { TRANSACTION_GET_DELAY } from '../constants/env';

export const useGetTransaction = (hash: string) => {
  const { data, error, query } = useQuery({
    path: routes.transaction,
    params: { id: hash, parameters: ['block', 'decodeInput'] },
  });

  useEffect(() => {
    const id = setInterval(() => query(), TRANSACTION_GET_DELAY);

    return () => {
      clearInterval(id);
    };
  }, []);

  return { data, error };
};

export const useGetTransactionInfo = (data?: Transaction[]) => {
  const [info, setInfo] = useState<TransactionInfo[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!data?.length || !data?.[0]?.hash) return;
      setLoading(true);
      const result = data.map((item) => toLocalTransaction(item));
      const promiseAll = await Promise.all(result).then((data) => data.filter((item) => item?.hash));
      setInfo(promiseAll as unknown as TransactionInfo[]);
      setLoading(false);
    };
    getData();
  }, [data?.[0]?.hash, data?.length]);

  return { info, loading };
};

export const useGetBlockTransactions = (hash: string) => {
  const [transactions, setTransactions] = useState<Array<TransactionType>>();
  const [isFetch, setFetch] = useState(true);
  const {
    data: response, url, query, error,
  } = useQuery({
    path: `${routes.block}/${hash}/tx`,
    params: { limit: 15 },
  });

  const fetchMore = async () => {
    if (response?.paging?.nextCursor && isFetch) {
      setFetch(false);
      url.searchParams.set('cursor', response?.paging.nextCursor);
      await query();
      setFetch(true);
    }
  };

  useEffect(() => {
    const actualData = response?.data;
    if (!actualData || !actualData.length) return;
    const newData = transactions || [];
    actualData.forEach((tx: TransactionType) => {
      if (transactions?.find((t) => t?.hash === tx?.hash)) {
        return;
      }
      newData.push(tx);
    });
    setTransactions(newData);
  }, [response?.paging?.nextCursor]);

  return {
    data: transactions, fetchMore, hasMore: (response?.paging.hasMore && isFetch), error,
  };
};
