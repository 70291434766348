export type routeTypes = 'main' | 'blocks' | 'block' | 'transaction' | 'account' | 'statistic'

export const routes: Record<routeTypes, string> = {
  main: '/',
  blocks: '/block',
  block: '/block',
  transaction: '/tx',
  account: '/address',
  statistic: '/statistic',
};

export const reactRoutes: Record<routeTypes, string> = {
  main: '/',
  blocks: '/block',
  block: `${routes.block}/:hash`,
  transaction: `${routes.transaction}/:hash`,
  account: `${routes.account}/:address`,
  statistic: '/statistic',
};

export const queryRoutes: Record<routeTypes, string> = {
  main: '/',
  blocks: '/api/blocks',
  block: '/api/block',
  transaction: '/api/transaction',
  account: '/api/account',
  statistic: '/api/balance',
};

export const queryParamsFields = {
  filter: 'filter',
  timestamp: 'timestamp',
  hash: 'hash',
};

export const queryParamsValues = {
  after: 'after',
};
