import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Block } from '../../components/Block';
import { useGetBlock } from '../../hook/block';
import { fullBlockType } from '../../types/block';
import { routes } from '../../constants/routes';

export const Container = () => {
  const { hash } = useParams<{ hash: string }>();
  const {
    data, fetchMore, hasMore, error,
  } = useGetBlock(hash);

  return <Block error={error} fetchMore={fetchMore} hasMore={hasMore} data={data as fullBlockType} />;
};

export const BlockContainer = () => {
  const [blockPageKey, setKey] = useState('block');

  useEffect(() => {
    setKey(routes.block + window.location.pathname);
  }, [window.location.pathname]);

  return <Container key={blockPageKey} />;
};
