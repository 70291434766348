import React, { ReactNode } from 'react';
import { ContentWrapper, CopyIconWrapper } from './styles';

interface Props {
  dataToCopy: string;
  children?: ReactNode;
}

export const CopyIcon: React.FC<Props> = ({ dataToCopy, children }) => {
  const saveToBuffer = () => {
    navigator.clipboard.writeText(dataToCopy);
  };
  return (
    <ContentWrapper onClick={saveToBuffer}>
      <CopyIconWrapper>
        <svg>
          <path d="M0.647476 4.40332H5.05038V5.6983H1.29499V16.058H12.3022V12.3026H13.5972V16.7055C13.5972 17.0631 13.3073 17.353 12.9497 17.353H0.647511C0.289656 17.353 3.8147e-05 17.0633 3.8147e-05 16.7055V5.05079C2.86102e-06 4.69318 0.289866 4.40332 0.647476 4.40332Z" />
          <path d="M5.05034 0H17.3525C17.7101 0 18 0.289829 18 0.647474V12.3022C18 12.6598 17.7101 12.9497 17.3525 12.9497H5.05034C4.69273 12.9497 4.40287 12.6598 4.40287 12.3022V0.647474C4.40283 0.289829 4.6927 0 5.05034 0ZM5.69782 11.6547H16.705V1.29495H5.69778V11.6547H5.69782Z" />
        </svg>
      </CopyIconWrapper>
      {children}
    </ContentWrapper>
  );
};
