import React, { ChangeEventHandler, useState } from 'react';
import web3 from 'web3';
import { useHistory } from 'react-router';
import { SearchComponent, variant } from '../components/Search';
import { queryRoutes, routes } from '../constants/routes';
import { Query } from '../hook/query';

export const Search: React.FC<{variant?: variant}> = ({ variant }) => {
  const [value, setValue] = useState('');
  const [errorMsg, setMsg] = useState('');
  const history = useHistory();
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
  };

  const searchByValue = async () => {
    setMsg('');
    if (!value) return false;
    if (web3.utils.isAddress(value)) {
      return history.push(`${routes.account}/${value}`);
    }
    try {
      const block = await Query(`${queryRoutes.block}/${value}`);
      if (!block.message) return history.push(`${routes.block}/${value}`);

      const tx = await Query(`${queryRoutes.transaction}/${value}`);
      if (!tx.message) return history.push(`${routes.transaction}/${value}`);
    } catch (e) {
      console.log(e);
    }

    setMsg('Search not found');
    return false;
  };

  return <SearchComponent value={value} onChange={onChange} onSubmit={searchByValue} variant={variant} errorMsg={errorMsg} />;
};
