import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import markImage from './assets/mark.png';
import { Text } from '../Typography/Text';

export const RowCell = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div:nth-child(1) {
    margin-right: 40px;
  }
`;

export const Liners = styled.svg`
  width: 100%;
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const PaginationWrapper = styled.div`
  position: fixed;
  right: 3%;
  @media (max-width: 560px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: #233848;
    bottom: 40px;
    right: 0;
    padding: 0 10px;
  }
`;

export const MarkWrapper = styled.div`
  position: absolute;
`;
export const GraphWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  @media (max-width: 560px) {
    overflow-x: scroll;
    ${Liners} {
      overflow: visible;
    }
  }
`;

export const HeightCell = styled.div`
  margin-bottom: 78px;
  font-family: Neo Sans Pro, sans-serif;
  font-style: normal;
  background-color: #233848;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #5593BC;
  text-align: right;
  width:  100px;
  @media (max-width: 560px) {
    text-align: left;
    margin: 3px 0 48px 10px;
    width:  95px;
  }
`;

export const MarkLink = styled.a`
  text-decoration: none;
  color: #5593BC;
  &:hover > div {
    display: flex;
  }
  position: relative;
  
  &::before {
    position: absolute;
    border: 10px solid transparent; /* Прозрачные границы */
    border-top: 10px solid #1F6CA5; /* Добавляем треугольник */
    left: 7px;
  }

  &:hover::before {
    content: '';
  }
  
`;

// export const MarkIcon = styled(Mark)`
//   &:hover > div {
//     display: flex;
//   }
// `;
export const MarkIcon = styled.img.attrs({
  src: markImage,
})`
  width: 35px;
  height: 56px;
  &:hover > div {
    display: flex;
  }
`;

export const MarkMenu = styled.div`
  display: none;
  background: rgba(38, 85, 126, 1);
  width: 178px;
  border: 1px solid #1F6CA5;
  bottom: calc(100% + 43px);
  left: -70px;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
  position: absolute;
  padding: 3px 21px 3px 11px;
  &:hover {
    display: flex;
  }
`;

export const MarkMenuText = styled(Text).attrs({ size: 11, color: '#5593BC' })`
  display: flex;
  justify-content: space-between;
  line-height: 22px;
  & > span {
    &:first-child {
      margin-right: 10px;
    }
    &:nth-child(2) {
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const Pagination = styled(ReactPaginate).attrs({
  activeClassName: 'active',
})`
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style-type: none;
  font-family: Roboto;
  li {
    width: 45px;
    margin-bottom: 5px;
  }
  li a {
    padding: 2px 0;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    border: gray 1px solid;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.87);
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
    text-decoration: none;
  }
  li.active a {
    background-color: #55BAFF;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
  @media (max-width: 560px) {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 0 0;
    margin: 0;
  }
`;

export const CountBlocksWrapper = styled.div`
  position: fixed;
  right: 3%;
  top: 11%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 560px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    background-color: #233848;
    bottom: 0;
    top: auto;
    right: 0;
    padding: 10px;
  }
`;

export const SelectCount = styled.input.attrs({ type: 'number' })`
  background-color: rgba(255,255,255,0.87);
  border-radius: 7px;
  border: 0;
  width: 70px;
  outline: none;
  text-align: center;
  font-family: Roboto;
`;
