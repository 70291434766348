import styled from 'styled-components';
import { Text } from '../Typography/Text';

export const Title = styled(Text).attrs({
  color: 'blue',
  size: 24,
})`
    display: inline-block;
    margin-bottom: 40px;
  @media(max-width: 560px){
    margin-left: 10px;
  }
    @media(max-width: 425px){
      margin-bottom: 20px;
    }
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
