import React from 'react';
import { ColumnType } from '../Table/types';
import { TransactionType } from '../../types/transaction';
import { routes } from '../../constants/routes';
import { formatDate } from '../../helper/formatDate';
import { Label } from '../common';

export const TransactionCol: ColumnType<TransactionType>[] = [
  {
    title: 'Nonce',
    dataKey: 'nonce',
  },
  {
    title: 'Status',
    dataKey: 'status',
    render: (items) => (typeof items?.status !== 'undefined' ? <Label status={items?.status} /> : null),
  },
  {
    title: 'From',
    dataKey: 'from',
    getLink: (str) => `${routes.account}/${str}`,
  }, {
    title: 'To',
    dataKey: 'to',
    getLink: (str) => `${routes.account}/${str}`,
  }, {
    title: 'Value (Water)',
    dataKey: 'value',
  },
  {
    title: 'Gas price (Water)',
    dataKey: 'gasPrice',
  }, {
    title: 'Gas limit',
    dataKey: 'gas',
  },
];

export const txColumns: ColumnType<TransactionType>[] = [
  {
    title: 'Transactions',
    dataKey: 'hash',
  },
  {
    title: 'From',
    dataKey: 'from',
  },
  {
    title: 'To',
    dataKey: 'to',
  },
  {
    title: 'Value',
    dataKey: 'value',
  },
  {
    title: 'Timestamp',
    dataKey: (item) => item.timestamp && formatDate(item.timestamp),
    align: 'center',
  },
];
