import styled from 'styled-components';

export const BackButton = styled.button`
  padding: 10px 10px 10px 0;
  background: none;
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  top: 90px;
  left: 60px;
  cursor: pointer;
  border: none;
  :hover {
    & span {
      text-decoration: underline;
    }
  }

& > span:first-child{
  position: relative;
  display: inline-flex;
  align-items: center;
  
  &::before {
    content: '';
    position: absolute;
    left: -10px;
    width: 8px;
    height: 8px;
    border-top: 1px rgba(255,255,255,0.87) solid;
    border-left: 1px rgba(255,255,255,0.87) solid;
    transform: rotate(-45deg) translateY(-1px);
  }
}
    
  
  @media (max-width: 1200px) {
    left: 20px;
  }

  @media (max-width: 650px) {
    position: fixed;
    padding: 10px;
    background-color: #102636;
    top: 87vh;
    padding-left: 20px;
  }
`;
