import React from 'react';
import styled, { css } from 'styled-components';

interface IBox {
  flex?: boolean
  relative?: boolean
  absolute?: boolean
  column?: boolean
  items?: 'center' | 'start' | 'end'
  justify?: 'center' | 'space-between' | 'space-around' | 'start' | 'end'
  inset?: string
  padding?: string
  margin?: string
  gap?: string
  width?: string | number
  height?: string | number
  minWidth?: string | number
  minHeight?: string | number
  background?: string
}

interface ComponentProps extends Omit<IBox, 'flex' | 'relative' | 'absolute' | 'column'> {
  $flex?: boolean
  $relative?: boolean
  $absolute?: boolean
  $column?: boolean
}

const Component = styled.div<ComponentProps>`
  ${({ $flex }) => $flex && css`display: flex;`}
  ${({ $column }) => $column && css`flex-direction: column;`}
  ${({ padding }) => padding && css`padding: ${padding};`}
  ${({ margin }) => margin && css`margin: ${margin};`}
  ${({ gap }) => gap && css`gap: ${gap};`}
  ${({ inset }) => inset && css`inset: ${inset};`}
  ${({ items }) => items && css`align-items: ${items};`}
  ${({ justify }) => justify && css`justify-content: ${justify};`}
  ${({ width }) => width && css`width: ${typeof width === 'number' ? `${width}px` : width};`}
  ${({ height }) => height && css`height: ${typeof height === 'number' ? `${height}px` : height};`}
  ${({ minWidth }) => minWidth && css`min-width: ${typeof minWidth === 'number' ? `${minWidth}px` : minWidth};`}
  ${({ minHeight }) => minHeight && css`min-height: ${typeof minHeight === 'number' ? `${minHeight}px` : minHeight};`}
  ${({ background }) => background && css`background: ${background};`}
  ${({ $relative, $absolute }) => {
    if ($relative) return css`position: relative;`;
    if ($absolute) return css`position: absolute;`;
    return '';
  }}
`;

export const Box: React.FC<IBox & Partial<React.HTMLAttributes<HTMLDivElement>>> = ({
  children, column, relative, absolute, flex, ...props
}) => <Component {...props} $column={column} $flex={flex} $absolute={absolute} $relative={relative}>{children}</Component>;
