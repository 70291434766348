import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { Account } from '../../components/Account';
import { useGetAccount, useGetToken } from '../../hook/account';
import { routes } from '../../constants/routes';
import { useGetTransactionInfo } from '../../hook/transaction';
import { Layout } from '../../components/Layout';
import { Token } from '../../components/Token';

export const Container = () => {
  const { address } = useParams<{ address: string }>();
  const {
    data: accountData, error: errorAccount, hasMore: hasMoreAccount, fetchMore: fetchMoreAccount,
  } = useGetAccount(address);
  const {
    data: tokenData, error: errorToken, hasMore: hasMoreToken, fetchMore: fetchMoreToken,
  } = useGetToken(address);
  const { info } = useGetTransactionInfo(tokenData?.transactions);

  if (!accountData?.address || !tokenData?.token) {
    return (
      <>
        <Layout>
          <PacmanLoader loading color="#008eee" speedMultiplier={2} />
        </Layout>
      </>
    );
  }

  if (!tokenData?.token?.hash) {
    return <Account hasMore={hasMoreAccount} fetchMore={fetchMoreAccount} data={accountData} error={errorAccount} />;
  } return <Token hasMore={hasMoreToken} fetchMore={fetchMoreToken} data={tokenData} error={errorToken} transactionInfo={info} />;
};

export const AccountContainer = () => {
  const [blockPageKey, setKey] = useState('account');

  useEffect(() => {
    setKey(routes.block + window.location.pathname);
  }, [window.location.pathname]);

  return <Container key={blockPageKey} />;
};
