import styled from 'styled-components';

export const TableContainer = styled.table`
  white-space: nowrap;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
`;

export const Body = styled.tbody`
  background-color: #2D4557;
  width: 100%;
  
  & td:first-child {
    width: 300px;
  }
`;

export const Caption = styled.caption`
  background-color: #273F52;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.21);
  padding: 19px 16px;
  text-align: left;
`;

export const CopyButton = styled.button`
  background-color: #102636;
  color: #fff;
  border-radius: 5px;
  border: 0;
  font-family: Roboto;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  outline: none;
  
  &:hover {
    background-color: #55BAFF;
  }
  &:active {
    background-color: rgba(16, 38, 54, 0.6);
  }
`;

export const CopyText = styled.span`
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  vertical-align: text-bottom;
  @media(max-width: 768px){
    max-width: 550px;
  }
  @media(max-width: 425px){
    max-width: 230px;
  }
  @media(max-width: 375px){
    max-width: 200px;
  }
  @media(max-width: 320px){
    max-width: 170px;
  }
`;
