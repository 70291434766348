import { useEffect, useRef, useState } from 'react';
import { SITE_URL } from '../constants/env';
import { clear } from '../helper/common';

interface queryParams {
    path: string
    params?: {
        id?: string
        cursor?: string
        after?: string
        limit?: number
        parameters?: string[];
    }
    queryParams?: Record<string, string | number | undefined | null>
}

// eslint-disable-next-line
export const useQuery = <T = any>({ path, params, queryParams }: queryParams) => {
  const {
    id, cursor, limit, parameters,
  } = params || {};
  const [error, setError] = useState<string>('');
  const { current: url } = useRef(
    new URL(SITE_URL + path + (id ? `/${id}` : '')),
  );
  useEffect(() => {
    if (!limit) return;
    url.searchParams.set('limit', limit.toString());
  }, [limit]);

  useEffect(() => {
    if (!cursor) return;
    url.searchParams.set('cursor', cursor);
  }, [cursor]);

  useEffect(() => {
    if (!parameters) return;
    url.searchParams.set('parameters', `[${parameters.reduce((acc, item) => `${acc},${item}`)}]`);
  }, [parameters]);

  useEffect(() => {
    const queryParamsEntries = Object.entries(clear(queryParams || {}));
    if (!queryParamsEntries.length) return;

    queryParamsEntries.forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });
  }, [queryParams]);

  const [data, setData] = useState<T | undefined>();

  const Fetch = async () => {
    try {
      const response = await fetch(url.href, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      const parsed = await response.json();
      setData(parsed);
      setError('');
    } catch (e) {
      setError('Error');
      console.log('Error', e);
    }
  };

  useEffect(() => {
    Fetch();
  }, []);
  return {
    data,
    url,
    error,
    query: Fetch,
  };
};

export async function Query(path?: string) {
  try {
    const response = await fetch(SITE_URL + path, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    const parsed = await response.json();
    return parsed;
  } catch (e) {
    console.log('Error', e);
  }
}
