import { queryRoutes as routes } from '../constants/routes';
import { StatisticTopType, StatisticType } from '../types/statistic';
import { useQuery } from './query';

export const useGetStatistic = () => {
  const { data: statistic, error: statisticError } = useQuery<StatisticType>({ path: `${routes.statistic}/statistic` });

  const { data: top, error: topError } = useQuery<StatisticTopType>({ path: `${routes.statistic}/top` });

  return {
    statistic,
    statisticError,
    top,
    topError,
  };
};
