import React, { useEffect, useState } from 'react';
import InfinityScroll from 'react-infinite-scroller';
import { MainLayout } from '../Layout';
import {
  GraphWrapper,
  HeightCell,
  LayoutRow,
  Liners,
  MarkIcon,
  MarkLink,
  MarkMenu,
  MarkMenuText,
  MarkWrapper,
} from './styles';
import { BlockType } from '../../types/block';

import { Size, useWindowSize } from '../../hook/layout';
import { SLOTS_PER_EPOCH } from '../../constants/env';
import { Filter } from '../Filter';

interface Props {
    blocks: Array<BlockType>
    hasMore?: boolean
    fetchMore: (page: number) => void
}

export const background = '#233848';

type blockData = {
    list: Array<Array<BlockType>>
    object: { [hash: string]: { row: number; column: number } }
}

const getY = (row: number, windowSize: Size) => row * ((windowSize.width && (windowSize.width <= 560)) ? 70 : 100);
const columnWidthMax = 160;
const columnWidthMin = 60;
const getX = (column: number, maxColumnInRow: number, maxColumn: number, windowSize: Size) => {
  let columnWidth = 120;
  let left = 0;

  if (windowSize.width) {
    const width = windowSize.width - (windowSize.width <= 560 ? 30 : 200);
    columnWidth = width / maxColumn;
    if (columnWidth > columnWidthMax) columnWidth = columnWidthMax;
    if (columnWidth < columnWidthMin) columnWidth = columnWidthMin;

    left += (width - maxColumn * columnWidth) / 2;
  }

  if (maxColumn > maxColumnInRow) {
    left += (maxColumn - maxColumnInRow) * columnWidth * 0.5;
  }

  return (left + column * columnWidth) / (windowSize.width && (windowSize.width <= 560) ? 1.5 : 1);
};

const getColorArrow = (blockFrom: BlockType, blockTo: BlockType) => (blockFrom.height === blockFrom.number && blockTo.height === blockTo.number ? '#00E8F7' : '#008FE0');

export const Main: React.FC<Props> = ({ blocks, hasMore, fetchMore }) => {
  const [data, setData] = useState<blockData>({ list: [], object: {} });

  const windowSize: Size = useWindowSize();
  const { list, object } = data;
  useEffect(() => {
    if (!blocks || blocks.length === 0) return;
    const newData: Array<Array<BlockType>> = [[]];
    const newBlockObject: { [hash: string]: { row: number; column: number } } = {};
    let lastEpochSlot = blocks[0].slot;
    blocks.forEach((b) => {
      const epochSlot = b.slot;
      if (epochSlot < lastEpochSlot) {
        newData[newData.length - 1] = newData[newData.length - 1].sort((a, b) => {
          if (a.miner < b.miner) {
            return -1;
          }
          if (a.miner > b.miner) {
            return 1;
          }
          // a must be equal to b
          return 0;
        });
        lastEpochSlot = epochSlot;
        newData.push([]);
      }
      const row = newData.length - 1;
      newData[row].push(b);
      const column = newData[row].length - 1;
      newBlockObject[b.hash] = { row, column };
    });
    setData({ list: newData, object: newBlockObject });
  }, [blocks]);

  const maxColumn = list.reduce((prev, cur) => (prev >= cur.length - 1 ? prev : cur.length), 0);

  return (
    <>
      <MainLayout>
        <Filter />
        <LayoutRow>
          <div>
            {list.map((row) => (
              <HeightCell key={`label-${row[0].slot}`}>
                {`${Math.floor(row[0].slot / SLOTS_PER_EPOCH)}(${(row[0].slot % SLOTS_PER_EPOCH) + 1})`}
              </HeightCell>
            ))}
          </div>
          <GraphWrapper>
            {list.map((row, rowIndex) => row.map((block, blockIndex) =>
            // const blockNumber = blocks.indexOf(block);

              // if (blockNumber % itemsOnPage === 0) {
              //   anchors.push(block.hash);
              // }
            // eslint-disable-next-line implicit-arrow-linebreak
              (
                <MarkWrapper
                  id={block.hash}
                  key={block.hash}
                  style={{
                    top: `${getY(rowIndex, windowSize)}px`,
                    left: `${getX(blockIndex, row.length - 1, maxColumn, windowSize)}px`,
                  }}
                >
                  <MarkLink href={`/block/${block.hash}`} target="_blank">
                    <MarkIcon />
                    <MarkMenu>
                      <MarkMenuText>
                        <span>Hash: </span>
                        <span>{block.hash}</span>
                      </MarkMenuText>
                      <MarkMenuText>
                        <span>Author: </span>
                        <span>{block.miner}</span>
                      </MarkMenuText>
                      <MarkMenuText>
                        <span>Height: </span>
                        <span>{block.height}</span>
                      </MarkMenuText>
                      <MarkMenuText>
                        <span>Number: </span>
                        <span>{block.number}</span>
                      </MarkMenuText>
                      <MarkMenuText>
                        <span>E(S): </span>
                        <span>{`${Math.floor(block.slot / SLOTS_PER_EPOCH)}(${(block.slot % SLOTS_PER_EPOCH) + 1})`}</span>
                      </MarkMenuText>
                      <MarkMenuText>
                        <span>Txs: </span>
                        <span>{block.transactions.length}</span>
                      </MarkMenuText>
                    </MarkMenu>
                  </MarkLink>
                </MarkWrapper>
              )))}
            <Liners height={list.length * (windowSize.width && (windowSize.width <= 560) ? 70 : 100)} width={windowSize.width ? windowSize.width - 100 : 600}>
              <rect
                height={list.length * (windowSize.width && (windowSize.width <= 560) ? 70 : 100)}
                width={windowSize.width ? windowSize.width - 100 : 600}
                fill={background}
              />
              {list.map((row, rowIndex) => row.map((block, blockIndex) => block.parentHashes
                .filter((parent) => object[parent])
                .map((parent) => (
                  <g key={`group-${block.hash}-${parent}-${blocks[0].hash}`}>
                    <defs key={`marker-${block.hash}-${parent}-${blocks[0].hash}`}>
                      <marker
                        id="arrow"
                        viewBox="0 -5 10 10"
                        refX="25"
                        refY="0"
                        markerWidth="16"
                        markerHeight="4"
                        orient="auto"
                        fill={getColorArrow(
                          block,
                          list[object[parent].row][object[parent].column],
                        )}
                      >
                        <path
                          d="M0,-5L10,0L0,5"
                          className="arrowHead"
                          fill={getColorArrow(
                            block,
                            list[object[parent].row][object[parent].column],
                          )}
                        />
                      </marker>
                    </defs>
                    <line
                      key={`line-${block.hash}-${parent}-${blocks[0].hash}`}
                      x1={getX(blockIndex, row.length - 1, maxColumn, windowSize) + 20}
                      y1={getY(rowIndex, windowSize) + 30}
                      x2={
                                                          getX(
                                                            object[parent].column,
                                                            list[object[parent].row].length - 1,
                                                            maxColumn,
                                                            windowSize,
                                                          ) + 17
                                                      }
                      y2={getY(object[parent].row, windowSize) + 20}
                      strokeWidth={(windowSize.width && (windowSize.width <= 560)) ? 1 : 2}
                      stroke={getColorArrow(
                        block,
                        list[object[parent].row][object[parent].column],
                      )}
                      strokeOpacity={0.6}
                      strokeDasharray="8,4"
                      markerEnd="url(#arrow)"
                    />
                  </g>
                ))))}
            </Liners>
          </GraphWrapper>
        </LayoutRow>
        {/* <SelectCountBlocks count={itemsOnPage} changeCount={changeCountItems} /> */}
        {/* <PaginationContainer handlePageClick={handlePageClick} pageCount={anchors.length} /> */}
      </MainLayout>
      <InfinityScroll hasMore={hasMore} loadMore={fetchMore} />
    </>
  );
};
